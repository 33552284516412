import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Developer workflow`}</h1>
    <p>{`The goal of the Nulogy Design System is to be able to build entire interfaces exclusively out of NDS components and the properties they expose. This ensures we're building centralized, consistent and themeable interfaces. We've made great progress towards this goal over the past few years but it's expected that you'll still find gaps in the system, especially as new experiences are designed. For these, we provide building blocks for generating custom components.`}</p>
    <p>{`Read on to learn about the different types of NDS components and how they can best be leveraged in your application.`}</p>
    <h2>{`High Level Components`}</h2>
    <p>{`These are the components you'd come to expect from a design system, like `}<a parentName="p" {...{
        "href": "/components/buttons"
      }}>{`Buttons`}</a>{`, `}<a parentName="p" {...{
        "href": "/components/input"
      }}>{`Inputs`}</a>{`, and `}<a parentName="p" {...{
        "href": "/components/alert"
      }}>{`Alerts`}</a>{`. They are designed to look and behave a certain way and their appearance is intentional. There are matching versions of these components inside the Figma UI kit. All high level components have properties for changing the margins so they can be placed next to each other without creating new instances.`}</p>
    <p>{`Here's an example of composing some high level components together for a simple view:`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1024px"
      }
    }}>{`
      `}<a parentName="span" {...{
        "className": "gatsby-resp-image-link",
        "href": "/static/f32857de49b103ec083d28192040aa2b/16bd1/developer-workflow-modal.png",
        "style": {
          "display": "block"
        },
        "target": "_blank",
        "rel": "noopener"
      }}>{`
    `}<span parentName="a" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "52.34375%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAABYlAAAWJQFJUiTwAAABI0lEQVQoz62S70uDYBDH/cd7kU3XBqm1LILp1rQXQQwLKvoFG0aOdDZK/4gIlLEUWsY3bmKpW6MXe+DDwT1337vjjqnLOmq72spg6rKGzUanwKJAiqPiGeQr5xEMfWYima3uHKIitsBJLVSk1LKCCnZL+YFi8jm5DvWCk99u40AzcG06uOrbuDFT7u1nWK6Hh6GHgeujeXQKTmrPj1zLCZI4dXJydgfEY3xFISZBiOAtwDSKgSQBPhPQ6170wAoKyjsoCWrYEFUcG7dwnAD9xxDh6wR4j5CMycYpH1N0z3uz4nOCi0be7xjgtRHWmi4uzRfYIx+W68MaejMGT0tG/mspgqxC3FPBiQrWc8vI4P+7lF9xDdUG+fTCuZTPZmmHqzjsb8FFQIhFV6C4AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="a" {...{
          "className": "gatsby-resp-image-image",
          "alt": "developer workflow modal",
          "title": "developer workflow modal",
          "src": "/static/f32857de49b103ec083d28192040aa2b/2bef9/developer-workflow-modal.png",
          "srcSet": ["/static/f32857de49b103ec083d28192040aa2b/6f3f2/developer-workflow-modal.png 256w", "/static/f32857de49b103ec083d28192040aa2b/01e7c/developer-workflow-modal.png 512w", "/static/f32857de49b103ec083d28192040aa2b/2bef9/developer-workflow-modal.png 1024w", "/static/f32857de49b103ec083d28192040aa2b/71c1d/developer-workflow-modal.png 1536w", "/static/f32857de49b103ec083d28192040aa2b/a878e/developer-workflow-modal.png 2048w", "/static/f32857de49b103ec083d28192040aa2b/16bd1/developer-workflow-modal.png 2922w"],
          "sizes": "(max-width: 1024px) 100vw, 1024px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
  `}</a>{`
    `}</span>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Modal title="Stopping job #250123">
  <Alert type="danger" mb="x2">
    This action is unrecoverable. Are you sure?
  </Alert>
  <PrimaryButton>Yes, stop the job</PrimaryButton>
</Modal>
`}</code></pre>
    <p>{`If you find yourself tempted to override styles on one of these components, there's a high likelihood that you've discovered a new component that should be built up out of building blocks instead.`}</p>
    <h2>{`Building Blocks`}</h2>
    <p>{`We expose building blocks to create custom, one-off or experimental experiences while still utilizing Nulogy's theme and design language. These are small `}<a parentName="p" {...{
        "href": "https://styled-components.com/"
      }}>{`styled-components`}</a>{` that can be composed together to create anything in Nulogy's style. These are:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/box"
        }}>{`Box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/flex"
        }}>{`Flex`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/link"
        }}>{`Link`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/list"
        }}>{`List`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/text"
        }}>{`Text`}</a></li>
    </ul>
    <p>{`Each of these components create exactly one element in the DOM, and have properties exposed for changing their visual styles. This eliminates the need to create redundant components and guarantees that their styles can be themed for different use cases in the future. For more details, see the documentation page for each component.`}</p>
    <h3>{`The "as" prop`}</h3>
    <p>{`Since our building blocks are styled-components, they come with the `}<a parentName="p" {...{
        "href": "https://styled-components.com/docs/api#as-polymorphic-prop"
      }}>{`"as"`}</a>{` property. This allows you to change the element rendered on the page, while keeping existing styling and props.`}</p>
    <p>{`For example, to use the Box component to create a table cell instead of the default div:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box as="td" m="x1" color="darkGrey" />
`}</code></pre>
    <h3>{`Responsive styles`}</h3>
    <p>{`All building blocks can be styled responsively, allowing different experiences for different devices.`}</p>
    <p>{`For example, to change the size and background colour based on screen size:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box
  width={{ extraSmall: "100%", small: "250px", medium: "800px" }}
  bg={{ extraSmall: "blue", small: "darkBlue", medium: "blackBlue" }}
/>
`}</code></pre>
    <h3>{`The theme`}</h3>
    <p>{`All of the design values are stored in one place called our theme. When changing a style property on a component, the theme will be checked first.`}</p>
    <p>{`For example, to create a blue Box with white text and 24px of padding you can use:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Box bg="darkBlue" color="white" p="x3">
  Box
</Box>
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`darkBlue`}</inlineCode>{`, `}<inlineCode parentName="p">{`white`}</inlineCode>{`, and `}<inlineCode parentName="p">{`x3`}</inlineCode>{` are all pulled from the appropriate object in the theme file. This helps us stay scalable as we can now change what "darkBlue" is to Nulogy and propogate that change across our applications.`}</p>
    <p>{`For more information on the theme, see the `}<a parentName="p" {...{
        "href": "/theme"
      }}>{`theme`}</a>{` documentation or an individual building block's API documentation.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      